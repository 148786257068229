<template>
  <div class="numboard">
    <div class="numboard__row">
      <button class="numboard__btn" @click="clickNum('1')" :disabled="disabled">
        1
      </button>
      <button class="numboard__btn" @click="clickNum('2')" :disabled="disabled">
        2
      </button>
      <button class="numboard__btn" @click="clickNum('3')" :disabled="disabled">
        3
      </button>
    </div>
    <div class="numboard__row">
      <button class="numboard__btn" @click="clickNum('4')" :disabled="disabled">
        4
      </button>
      <button class="numboard__btn" @click="clickNum('5')" :disabled="disabled">
        5
      </button>
      <button class="numboard__btn" @click="clickNum('6')" :disabled="disabled">
        6
      </button>
    </div>
    <div class="numboard__row">
      <button class="numboard__btn" @click="clickNum('7')" :disabled="disabled">
        7
      </button>
      <button class="numboard__btn" @click="clickNum('8')" :disabled="disabled">
        8
      </button>
      <button class="numboard__btn" @click="clickNum('9')" :disabled="disabled">
        9
      </button>
    </div>
    <div class="numboard__row">
      <button
        class="numboard__btn numboard__btn--clear"
        @click="clearNum"
        :disabled="disabled"
      >
        {{ $t("numboard.clear") }}
      </button>
      <button class="numboard__btn" @click="clickNum('0')" :disabled="disabled">
        0
      </button>
      <button class="numboard__btn" @click="deleteNum" :disabled="disabled">
        <img src="@/assets/img/delete-icon.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Numboard",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickNum(num) {
      this.$emit("setNum", num);
    },

    clearNum() {
      this.$emit("clear");
    },

    deleteNum() {
      this.$emit("delete");
    },
  },
};
</script>
