<template>
  <div
    class="sidebar"
    :style="{
      background: `linear-gradient(309.41deg, ${primaryColor} 1.65%, ${primaryColor}e6 84.64%)`,
    }"
  >
    <div class="sidebar__text-wrapper">
      <div v-show="mode === 'pin' && !pinIsFailure" class="sidebar__text">
        {{ $t("pin.enterPin") }}
      </div>

      <div v-show="pinIsFailure" class="sidebar__text">
        {{ $t("pin.errorPin") }}
      </div>

      <div
        v-show="mode === 'photo' || mode === 'checkPhoto'"
        class="sidebar__name"
      >
        {{ name }}
      </div>

      <div
        v-show="mode === 'photo' || mode === 'checkPhoto'"
        class="sidebar__text"
      >
        {{ $t("photo.takePhoto") }}
      </div>
    </div>

    <div class="sidebar__logo">
      <img :src="logo" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",

  computed: mapState({
    primaryColor: ({ themes }) => themes.primaryColor,
    logo: ({ themes }) => themes.logo,
    mode: ({ mode }) => mode.mode,
    pinIsFailure: ({ user }) => user.isFailure,
    name: ({ user }) => user.name,
  }),
};
</script>
