import axios from "@/plugins/vue-axios";

export default {
  check({ commit }, photo) {
    commit("setAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    axios
      .post("check-photo", {
        photo,
      })
      .then(() => {
        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });

        commit("mode/setMode", "done", { root: true });
      })
      .catch((error) => {
        const {
          response: {
            data: {
              status: { message },
            },
          },
        } = error;

        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });

        commit("setErrors", message);
      });
  },
};
