<template>
  <div class="pin">
    <div class="pin__dots">
      <template v-if="!isPending">
        <div
          v-for="pinIndex in pinLength"
          :key="pinIndex"
          :class="[
            'pin__dot',
            { 'pin__dot--active': pin.length >= pinIndex },
            { 'pin__dot--failure': isFailure },
          ]"
          :style="{ backgroundColor: primaryColor }"
        />
      </template>

      <img v-else src="@/assets/img/loader.svg" />
    </div>

    <Numboard
      :disabled="isPending"
      @setNum="onSetNum"
      @clear="onClear"
      @delete="onDelete"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Numboard from "./Numboard";

export default {
  name: "Pin",

  components: { Numboard },

  data() {
    return {
      pinLength: 6,
      pin: "",
    };
  },

  methods: {
    resetFailure() {
      this.$store.commit("user/setAsyncStatus", {
        isFailure: false,
        isPending: false,
        isSuccess: false,
      });
    },

    onSetNum(num) {
      this.resetFailure();

      const pin = this.pin + num;

      if (pin.length <= this.pinLength) {
        this.pin = pin;
      }

      if (this.pin.length === this.pinLength) {
        this.send();
      }
    },

    onClear() {
      this.resetFailure();

      this.pin = "";
    },

    onDelete() {
      this.resetFailure();

      this.pin = this.pin.slice(0, -1);
    },

    async send() {
      await this.$store.dispatch("user/getUser", this.pin);

      this.pin = "";
    },
  },

  computed: mapState({
    primaryColor: ({ themes }) => themes.primaryColor,
    isSuccess: ({ user }) => user.isSuccess,
    isFailure: ({ user }) => user.isFailure,
    isPending: ({ user }) => user.isPending,
  }),
};
</script>
