import actions from "./actions";
import mutations from "./mutations";

export default {
  state: () => ({
    isPending: false,
    isSuccess: false,
    isFailure: false,

    primaryColor: "#7165FF",
    logo: "/ovision-logo.svg",
  }),

  actions,
  mutations,
  namespaced: true,
};
