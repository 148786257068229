<template>
  <div v-show="!isPending" id="app" class="page">
    <div class="page__left">
      <Sidebar />
    </div>

    <div class="page__right">
      <Pin v-if="mode === 'pin'" ref="pin" />
      <Photo v-if="mode === 'photo'" />
      <CheckPhoto v-if="mode === 'checkPhoto'" />
      <Done v-if="mode === 'done'" />
    </div>

    <v-idle v-show="false" @idle="onIdle" :duration="60" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "@/components/Sidebar";
import Pin from "@/components/Pin";
import Photo from "@/components/Photo";
import CheckPhoto from "@/components/CheckPhoto";
import Done from "@/components/Done";

export default {
  name: "App",

  components: { Pin, Photo, CheckPhoto, Done, Sidebar },

  computed: mapState({
    isPending: ({ themes }) => themes.isPending,
    mode: ({ mode }) => mode.mode,
  }),

  methods: {
    onIdle() {
      this.$store.commit("mode/setMode", "pin");

      if (this.$refs.pin) {
        this.$refs.pin.onClear();
      }
    },
  },

  created() {
    this.$store.dispatch("themes/getThemes");
  },
};
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
</style>
