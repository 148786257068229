<template>
  <div class="check-photo">
    <img v-if="isPending" src="@/assets/img/loader.svg" />

    <div v-else-if="isFailure" class="check-photo__wrapper">
      <div class="check-photo__error-list">
        <div class="check-photo__error-item">
          <img
            src="@/assets/img/face.svg"
            class="check-photo__error-item-img"
          />
          <span>{{ getLangMessage(errorMessages) }}</span>
        </div>
      </div>

      <div class="check-photo__btn-upload-wrapper">
        <input
          id="takePhoto"
          type="file"
          accept="image/*"
          capture="camera"
          class="btn__input"
          @change="onTakePhoto"
        />
        <label for="takePhoto" class="btn btn--danger">{{
          $t("photo.takePhotoAgain")
        }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import faceValidate from "@/mixins/faceValidate";
import { mapState } from "vuex";

export default {
  mixins: [faceValidate],

  name: "CheckPhoto",

  methods: {
    check() {
      this.$store.dispatch("checkPhoto/check", this.base64Photo);
    },

    onTakePhoto(e) {
      const photoBlob = e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(photoBlob);

      reader.onload = () => {
        const base64Photo = reader.result;
        this.$store.commit("photo/setPhoto", base64Photo);
        this.$store.commit("mode/setMode", "checkPhoto");

        this.check();
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    },
  },

  computed: mapState({
    base64Photo: ({ photo }) => photo.base64Photo,
    isPending: ({ checkPhoto }) => checkPhoto.isPending,
    isFailure: ({ checkPhoto }) => checkPhoto.isFailure,
    errorMessages: ({ checkPhoto }) => checkPhoto.errorMessages,
  }),

  created() {
    this.check();
  },
};
</script>
