import Vue from "vue";
import VueI18n from "vue-i18n";
import ru from "../lang/ru";
import en from "../lang/en";

Vue.use(VueI18n);

const i18n = new VueI18n({
  lazy: true,
  locale: "ru",
  messages: {
    ru,
    en,
  },
});

export default i18n;
