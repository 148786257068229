export default {
  methods: {
    getLangMessage(message) {
      this.parseUserIdLangMessage = null;
      let maxChar;

      if (message.indexOf("face match found User") !== -1) {
        this.parseUserIdLangMessage = message
          .replace("face match found User ", "")
          .trim();

        message = "Face match found";
      }

      if (message.indexOf("face match found User") !== -1) {
        this.parseUserIdLangMessage = message
          .replace("face match found User ", "")
          .trim();

        message = "Face match found";
      }

      if (message.indexOf("duplicate access card") !== -1) {
        const l = message.split("#");
        this.parseUserIdLangMessage = l[1].trim();

        message = "Duplicate access card";
      }

      if (message.indexOf("duplicate access CARD") !== -1) {
        const l = message.split("#");
        this.parseUserIdLangMessage = l[1].trim();

        message = "Duplicate access CARD";
      }

      if (message.indexOf("duplicate access QR") !== -1) {
        const l = message.split("#");
        this.parseUserIdLangMessage = l[1].trim();

        message = "Duplicate access QR";
      }

      if (message.indexOf("Image size is incorrect") !== -1) {
        message = "Image size is incorrect";
      }

      if (
        message.indexOf("pq: value too long for type character varying") !== -1
      ) {
        maxChar = message
          .replace("pq: value too long for type character varying", "")
          .trim();

        message = "pq: value too long for type character varying";
      }

      switch (message) {
        case "cant extract features for masks. SMALL FACE":
          return this.$t("messages.smallFace");

        case "cant extract features for masks. ":
          return this.$t("messages.cantExtractFeatureForMasks");

        case "Cant find face of the image":
        case "bad photo":
          return this.$t("messages.CantFaceImage");

        case "Unavailable to device":
        case "Device Unavailable":
          return this.$t("messages.UnavailableToDevice");

        case "face rotated":
          return this.$t("messages.faceRotated");

        case "Fields filled in incorrectly":
          return this.$t("messages.FieldsIncorrectly");

        case "Face match found":
          return `${this.$t("messages.FaceMatchFound")}`;

        case "No subdivision selected":
          return this.$t("messages.NoSubdivisionSelected");

        case 'pq: duplicate key value violates unique constraint "admins_username_key"':
          return this.$t("messages.duplicateKeyLogin");

        case "Duplicate access card":
        case "Duplicate access CARD":
          return `${this.$t("messages.DuplicateAccessCard")}`;

        case "Duplicate access QR":
          return `${this.$t("messages.DuplicateAccessQR")}`;

        case "Wrong landmarks":
          return this.$t("messages.wrongLandmarks");

        case "Image size is incorrect":
          return this.$t("messages.imageSizeIsIncorrect");

        case "pq: value too long for type character varying":
          return `${this.$t("messages.maxCharSize")} ${maxChar}`;

        case "Not found device":
          return this.$t("messages.notFoundDevice");

        case "duplicate phone":
          return this.$t("messages.phoneExist");

        case "The edge of the face bounding box matches the edge of the image":
          return this.$t("messages.boundingEdgeMatch");

        default:
          return message;
      }
    },
  },
};
