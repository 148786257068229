import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const instance = axios.create({
  baseURL: "/api/v1",
});

Vue.use(VueAxios, instance);

export default instance;
