import axios from "@/plugins/vue-axios";

export default {
  getThemes({ commit }) {
    commit("setAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    axios("theme")
      .then((response) => {
        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });

        const {
          data: {
            data: { logo, primaryColor },
          },
        } = response;

        if (primaryColor) {
          commit("setPrimaryColor", primaryColor);
        }

        if (logo) {
          commit("setLogo", logo);
        }
      })
      .catch(() => {
        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });
      });
  },
};
