export default {
  setAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPending = isPending;
    state.isSuccess = isSuccess;
    state.isFailure = isFailure;
  },

  setRegistrationAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPendingRegistration = isPending;
    state.isSuccessRegistration = isSuccess;
    state.isFailureRegistration = isFailure;
  },

  setUser(state, { id, name, company, address, dueFrom, dueTo }) {
    state.id = id;
    state.name = name;
    state.company = company;
    state.address = address;
    state.dueFrom = dueFrom;
    state.dueTo = dueTo;
  },
};
