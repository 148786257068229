import mutations from "./mutations";

export default {
  state: () => ({
    base64Photo: "",
  }),

  mutations,
  namespaced: true,
};
