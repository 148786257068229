export default {
  common: {
    send: "Отправка",
    cancel: "Отмена",
    processing: "Обработка",
    sending: "Отправка",
    back: "Назад",
    continue: "Продолжить",
  },

  messages: {
    CantFaceImage: "На фото нет лица",
    smallFace: "Вы слишком далеко. Подойдите к камере поближе.",
    UnavailableToDevice: "Устройство распознавания недоступно",
    faceRotated: "Неверно размещено лицо на фото",
    FieldsIncorrectly: "Некорректно заполнены поля",
    FaceMatchFound: "Найдено совпадение фото",
    NoSubdivisionSelected: "Отсутствуют уровни доступа",
    duplicateKeyLogin: "Такой Логин уже есть",
    DuplicateAccessCard: "Найден дубликат карты доступа",
    DuplicateAccessQR: "Найден дубликат QR доступа",
    wrongLandmarks: "Неверное положение лица",
    imageSizeIsIncorrect: "Минимальный размер изображения 500x500 пикселей",
    maxCharSize: "Имя файла превышает допустимое количество символов",
    notFoundDevice: "Нет зарегистрированных устройств",
    phoneExist: "Номер телефона уже привязан к другому пользователю",
    boundingEdgeMatch: "Неверно размещено лицо на фото",
  },

  pin: {
    enterPin: "Введите пин-код, отправленный в смс",
    errorPin: "Неверный пин-код, введите заново",
  },

  numboard: {
    clear: "стереть все",
  },

  photo: {
    takePhoto: "Сделайте фотографию для продолжения регистрации",
    termTitle: "Необходимо согласиться с условиями",
    termAgree: "Я ознакомлен и согласен с условиями",
    userAgreement: "Пользовательского соглашения",
    personalDataPolicies: "Политики персональных данных",
    takePhotoAgain: "Сделать фото заново",
  },

  faceAccess: {
    reshootPhoto: "Переснять фото",
    providePhoto: "Зарегистрироваться",
    noFaceOnPhotoMessage: "Пожалуйста сделайте фото где хорошо видно ваше лицо",
  },

  finish: {
    text: "Вы успешно зарегистрированы в системе и можете воспользоваться вашим приглашением!",
    from: "с",
    to: "до",
    indefinite: "Бессрочный",
    return: "Вернуться на главную",
  },
};
