<template>
  <div class="photo">
    <div class="photo__wrapper">
      <div class="photo__take">
        <div class="photo__term-title">{{ $t("photo.termTitle") }}</div>

        <div class="photo__term-block">
          <div class="photo__term">
            <label for="term" class="photo__term-label"
              >{{ $t("photo.termAgree") }}
              <button
                type="button"
                class="photo__term-link"
                :style="{ color: primaryColor }"
                @click="isTermDialogShow = true"
              >
                {{ $t("photo.userAgreement") }}
              </button>
            </label>
            <input
              v-model="term"
              type="checkbox"
              id="term"
              name="term"
              class="photo__term-checkbox"
            />

            <Dialog
              :is-visible="isTermDialogShow"
              @onClickBackstage="isTermDialogShow = false"
            >
              <Term />
            </Dialog>
          </div>

          <div class="photo__term">
            <label for="personal-data" class="photo__term-label"
              >{{ $t("photo.termAgree") }}
              <button
                type="button"
                class="photo__term-link"
                :style="{ color: primaryColor }"
                @click="isPersonalDataDialogShow = true"
              >
                {{ $t("photo.personalDataPolicies") }}
              </button>
            </label>
            <input
              v-model="personalData"
              type="checkbox"
              id="personal-data"
              name="term"
              class="photo__term-checkbox"
            />

            <Dialog
              :is-visible="isPersonalDataDialogShow"
              @onClickBackstage="isPersonalDataDialogShow = false"
            >
              <PersonalData />
            </Dialog>
          </div>

          <div class="photo__btn-wrapper">
            <input
              id="takePhoto"
              type="file"
              accept="image/*"
              capture="camera"
              class="btn__input"
              @change="onTakePhoto"
              :disabled="!term || !personalData"
            />
            <label
              for="takePhoto"
              class="btn"
              :style="{ backgroundColor: primaryColor }"
              >{{ $t("common.continue") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <canvas v-show="false" ref="resizeCanvas" width="1000" height="1000" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Dialog from "./Dialog";
import PersonalData from "./term/PersonalData";
import Term from "./term/Term";

export default {
  name: "Photo",

  components: { Dialog, PersonalData, Term },

  data() {
    return {
      term: false,
      personalData: false,
      isPersonalDataDialogShow: false,
      isTermDialogShow: false,
      base64Photo: "",
    };
  },

  watch: {
    base64Photo(base64Photo) {
      this.$store.commit("photo/setPhoto", base64Photo);
      this.$store.commit("mode/setMode", "checkPhoto");
    },
  },

  methods: {
    onTakePhoto(e) {
      const photoBlob = e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(photoBlob);

      reader.onload = () => {
        this.resizeImage(reader.result);
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    },

    resizeImage(base64Photo) {
      const canvas = this.$refs.resizeCanvas;
      const ctx = canvas.getContext("2d");
      const maxW = 1000;
      const maxH = 1000;

      var img = new Image();
      img.onload = () => {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        this.base64Photo = canvas.toDataURL("image/jpeg", 0.8);
      };
      img.src = base64Photo;
    },
  },

  computed: mapState({
    primaryColor: ({ themes }) => themes.primaryColor,
  }),
};
</script>
