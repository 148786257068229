export default {
  setAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPending = isPending;
    state.isSuccess = isSuccess;
    state.isFailure = isFailure;
  },

  setPrimaryColor(state, primaryColor) {
    state.primaryColor = primaryColor;
  },

  setLogo(state, logo) {
    state.logo = logo;
  },
};
