import actions from "./actions";
import mutations from "./mutations";

export default {
  state: () => ({
    isPending: false,
    isSuccess: false,
    isFailure: false,

    isPendingRegistration: false,
    isSuccessRegistration: false,
    isFailureRegistration: false,

    id: 0,
    name: "",
    company: "",
    address: "",
    dueFrom: "",
    dueTo: "",
  }),

  actions,
  mutations,
  namespaced: true,
};
