export default {
  setAsyncStatus(state, { isPending, isSuccess, isFailure }) {
    state.isPending = isPending;
    state.isSuccess = isSuccess;
    state.isFailure = isFailure;
  },

  setErrors(state, errors) {
    state.errorMessages = errors;
  },
};
