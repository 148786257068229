import actions from "./actions";
import mutations from "./mutations";

export default {
  state: () => ({
    isPending: false,
    isSuccess: false,
    isFailure: false,
    errorMessages: "",
  }),

  actions,
  mutations,
  namespaced: true,
};
