import axios from "@/plugins/vue-axios";

export default {
  getUser({ commit }, pin) {
    commit("setAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    axios
      .post("pin-auth", {
        pin,
      })
      .then((response) => {
        const {
          data: {
            data: { id, name, company, address, due_from, due_to, token },
          },
        } = response;

        axios.defaults.headers.Authorization = `Bearer ${token}`;

        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });

        commit("setUser", {
          id,
          name,
          company,
          address,
          dueFrom: due_from,
          dueTo: due_to,
        });

        commit("mode/setMode", "photo", { root: true });
      })
      .catch(() => {
        commit("setAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });
      });
  },

  registration({ commit, state }, photo) {
    commit("setRegistrationAsyncStatus", {
      isPending: true,
      isSuccess: false,
      isFailure: false,
    });

    axios
      .post(`registration/${state.id}`, {
        photo,
      })
      .then(() => {
        commit("setRegistrationAsyncStatus", {
          isPending: false,
          isSuccess: true,
          isFailure: false,
        });
      })
      .catch(() => {
        commit("setRegistrationAsyncStatus", {
          isPending: false,
          isSuccess: false,
          isFailure: true,
        });
      });
  },
};
